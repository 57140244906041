import { GET_CLIENT_USER } from '~/composables/queries/useClientUser'

export default defineNuxtRouteMiddleware(async () => {
  const user = useSupabaseUser()

  const client = useApi()

  const localePath = useLocalePath()

  try {
    // We can't re-use ClientUser composable here. It just breaks reactivity of the query store. I will postpone this thing now, because it's hard to resolve
    const { data } = await client.GET(GET_CLIENT_USER, {
      params: {
        path: {
          id: user.value!.id
        }
      }
    })

    if (!data || !data.currentMediaProject) {
      return navigateTo(localePath('publisher-setup'))
    }
  } catch (e) {
    console.error(e)
  }
})
